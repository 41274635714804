/* Define Custom Fonts */
@font-face {
    font-family: Quotes;
    src: url(/fonts/Sudtipos-QuotesScript-Regular.otf) format("opentype");
}

@font-face {
    font-family: Isidora;
    src: url(/fonts/Latinotype-Isidora-Sans-Regular.otf) format("opentype");
}
@font-face {
    font-family: Isidora;
    font-weight: bold;
    src: url(/fonts/Latinotype-Isidora-Sans-Bold.otf) format("opentype");
}
@font-face {
    font-family: Isidora;
    font-weight: lighter;
    src: url(/fonts/Latinotype-Isidora-Sans-Light.otf) format("opentype");
}

/* Define Custom Colors / Variables */
:root {
  --booking: rgba(254, 196, 46, 1);
  --booking-light: rgba(252, 234, 189, 1);
  --booking-transparent: rgba(254, 196, 46, 0.3);
  --first_lesson: rgba(135, 162, 70, 1);
  --first_lesson-light: rgba(216, 224, 196, 1);
  --first_lesson-transparent: rgba(135, 162, 70, 0.3);
  --event: rgba(122, 164, 221, 1);
  --event-light: rgba(212, 225, 242, 1);
  --event-transparent: rgba(122, 164, 221, 0.3);
  --test: rgba(219, 37, 50, 1);
  --test-light: rgba(241, 186, 190, 1);
  --test-transparent: rgba(219, 37, 50, 0.3);
  --reminder: rgba(245, 130, 31, 1);
  --reminder-light: rgba(252, 217, 187, 1);
  --reminder-transparent: rgba(245, 130, 31, 0.3);

  --dark-green: rgba(62, 109, 71, 1);
  --dark-green-light: rgba(197, 211, 199, 1);
  --dark-green-transparent: rgba(62, 109, 71, 0.3);
  
  --black65: rgba(0, 0, 0, 0.65);
  --antd-wave-shadow-color: var(--event);
}

/* App Level Layout */
body {
    min-width: 360px; /* Anything less than this and the display will start going wonky */
    overscroll-behavior-y: none; /* Disable pull to refresh and other overscroll behaviour */
}
.App {
    background-image: url(./images/background.jpg);
    background-size: cover;
    background-position-x: center;
    height: 100vh;
    text-align: center;
    font-family: 'Arial', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.App.pfg {
    background-image: url(./images/pfg-background.jpg);
    background-position-x: 75%;
}
h1, h2, h3, h4, h5, h6, 
th, thead td, 
b, strong, label, 
span.profileSectionHeader, 
.licenceDetailField,
.headerInput,
.ant-drawer-header,
.ant-modal-header,
.ant-menu-item {
    font-family: 'Isidora', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.ant-modal{
    min-width: 50vw;
}
#NotFound {
    width: 100%;
    height: 100%;
    background-image: url(./images/notfound.jpg);
    background-size: cover;
    background-position-x: center;
    background-position-y: 20%;
}
#NotFound p {
    padding-top: 5vh;
    font-size: 2.2vw;
}
/* Scale background image for speed on mobile resolutions - only load a relevant size */
@media (max-width: 2400px) {
    .App {
        background-image: url(./images/background-2400.jpg);
    }
    #NotFound {
        background-image: url(./images/notfound-2400.jpg);
    }
}
@media (max-width: 1200px) {
    .App {
        background-image: url(./images/background-1200.jpg);
    }
    #NotFound {
        background-image: url(./images/notfound-1200.jpg);
        background-position-x: 75%;
    }
}
@media (max-width: 800px) {
    .App {
        background-image: url(./images/background-800.jpg);
    }
}
/* Override some default colours */
.ant-badge-count {
    background: var(--test);
}
.ant-btn-primary,
.ant-btn-primary:hover,
.ant-btn-primary:focus {
    color: #fff !important;
    background-color: var(--event);
    border-color: var(--event-light);
}
.ant-btn-primary:disabled {
    color: silver !important;
    border-color: silver !important;
}
.ant-btn:active:not(.ant-btn-danger),
.ant-btn.active:not(.ant-btn-danger),
.ant-btn:hover:not(.ant-btn-danger),
.ant-btn:focus:not(.ant-btn-danger) {
    color: var(--event);
    border-color: var(--event);
}
.ant-btn-danger:active,
.ant-btn-danger.active {
    color: #fff;
    border-color: var(--event-light);
}
.ant-pagination-item-active,
.ant-input:hover {
    border-color: var(--event);
}
.ant-input:focus {
    box-shadow: 0 0 0 2px var(--event-light);
}
a,
a:hover,
.ant-pagination-item-active a,
.ant-pagination-item-active a:hover,
.ant-calendar-header a,
.ant-calendar-header a:hover {
    color: var(--event);
}
.ant-btn-danger {
    background-color: var(--test);
    border-color: var(--test-light);
}
.ant-calendar-today .ant-calendar-date {
    color: var(--event);
    border-color: var(--event);
}
.ant-calendar-date:hover,
.ant-calendar-selected-day .ant-calendar-date {
    background: var(--event-light);
    color: var(--black65);
}
::selection {
    background: var(--event) !important;
}
.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    border-color: var(--event);
    background: var(--event-light);
}
#calendarWrapper .fc-button {
    border-radius: 10px;
}
.fc-button-group > .fc-button:not(:last-child) {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.fc-button-group > .fc-button:not(:first-child) {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
.fc-button-primary,
.ant-switch-checked,
.ant-checkbox-checked .ant-checkbox-inner {
    color: #fff !important;
    background-color: var(--event) !important;
    border-color: #fff !important;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: var(--event) !important;
}
.ant-checkbox-checked::after {
    border: var(--event) !important;
}
.ant-switch-checked .ant-switch-inner {
    color: #fff !important;
}
.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #fff;
}
.ant-form-item-required::before {
    color: var(--test);
}
.ant-row.ant-form-item.inline {
    display: table-cell;
    width: 50%;
}
.dt-signoff-modal .ant-form-item {
    margin-bottom: 0;
    width: 93%;
}
.dt-signoff-modal .ant-checkbox-inner {
    width: 30px;
    height: 30px;
}
.dt-signoff-modal .ant-checkbox-inner::after {
    width: 10px;
    height: 20px;
}
.ant-alert-error {
    border-color: var(--test);
    background-color: var(--test-transparent);
    color: var(--black65);
    margin: 10px;
}
.fc-unthemed td.fc-today {
    background: #f3ffff !important;
}
.red-icon {
    color: var(--test);
}
.anticon-loading {
    margin-right: 10px;
}
.fc-time-grid .fc-slats .fc-minor td {
    border-top-color: transparent;
}
.has-success.has-feedback .ant-form-item-children-icon{
    color: var(--first_lesson);
}
.ant-menu-item-selected {
    color: var(--black65);
}
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
    color: var(--black65);
    cursor: default;
}

/* Login Screen */
.login-screen {
    width: 100%;
    height: 100%;
}
.login-container {
    background-color: rgba(255, 255, 255, 0.6);
    color: #000;
    position: fixed;
    right: 15%;
    bottom: 15%;
    width: 30%;
    padding: 5px 25px;
}
h1.product-name {
    font-family: 'Quotes', cursive;
    font-weight: bold;
    font-size: 35px;
    border-bottom-width: 5px;
    border-bottom-color: var(--booking);
    border-bottom-style: solid;
    padding-bottom: 5px;
}
.big-logo {
    width: 70%;
    pointer-events: none;
    max-width: 500px;
    padding: 10px;
}
.ant-form-item-children button:not(.ant-btn-dashed),
.ant-form-item-children a {
    width: 45% !important;
    margin: 0 2px;
}
@media (max-width: 1024px) {
    .login-container {
        right: 10%;
        width: 40%;
    }
}
@media (max-width: 1024px) and (max-height: 1400px) and (min-height: 800px) {
    .login-container {
        height: auto;
        bottom: 20%;
        right: 15%;
    }
}
@media (max-width: 900px) {
    .login-container {
        right: 10%;
        width: 50%;
        padding: 5px 25px;
    }
}
@media (max-width: 767px) {
    .login-container {
        position: fixed;
        width: 75%;
        bottom: 7.5%;
        left: 12.5%;
        right: 12.5%;
    }
    h1.product-name {
        font-size: 20px;
        margin-bottom: 10px;
    }
    .ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label {
        padding-bottom: 2px;
    }
    .ant-form-item {
        padding-bottom: 2px !important;
        margin-bottom: 5px;
    }
}
@media (max-width: 767px) and (max-height: 420px) {
    .big-logo {
        width: 50%;
    }
}
@media (max-width: 767px) and (max-height: 320px) {
    .big-logo {
        padding: 2px;
    }
}
@media (max-width: 640px) {
    .ant-form-item-children {
        display: block;
    }
    .ant-form-item-children button:not(.ant-btn-dashed),
    .ant-form-item-children a {
        width: 100% !important;
        margin-bottom: 5px;
    }
    #loginForm .ant-btn-danger {
        margin-left: initial;
    }
}
@media (max-width: 420px) {
    .login-container {
        position: fixed;
        width: 75%;
        bottom: 25%;
        left: 12.5%;
        right: 12.5%;
    }
}
@media (max-height: 420px) and (min-width: 760px) {
    .login-container {
        position: fixed;
        width: 75%;
        bottom: 10%;
        left: 12.5%;
        right: 12.5%;
    }
    h1.product-name {
        font-size: 25px;
        margin-bottom: 0;
    }
    .big-logo {
        width: 50%;
    }
    .ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label {
        padding-bottom: 2px;
    }
    .ant-form-item {
        padding-bottom: 2px !important;
        margin-bottom: 5px;
    }
    .ant-form-item-control {
        max-width: 100% !important;
    }
}
#loginForm {
    margin: auto;
}
#loginForm label {
    font-weight: bold;
    color: var(--black65);
}
#loginForm .ant-form-item-required::before {
    display: none;
}
#loginForm .ant-btn {
    border-radius: 20px;
}
.bad-test-modal .ant-btn-primary,
#loginForm .ant-btn-primary {
    background-color: #28a745;
    border-color: #28a745;
}
.bad-test-modal .ant-btn-primary:hover,
.bad-test-modal .ant-btn-primary:focus,
#loginForm .ant-btn-primary:hover,
#loginForm .ant-btn-primary:focus {
    background-color: #218838;
    border-color: #1e7e34;
}
/* End Login Screen */

/* Top Navigation */
header {
    z-index: 0 !important;
}
a.nav-logo-link {
    position: absolute;
    width: 160px;
    height: 25px;
    top: 20px;
    left: 10px;
}
a.nav-logo-link.collapsed {
    width: 40px;
    height: 40px;
    top: 11px;
}
.nav-logo {
    pointer-events: none;
    max-width: 160px;
    transition: opacity 0.5s ease-out;
    opacity: 1;
    position: absolute;
    top: 0;
}
.nav-logo.hide {
    opacity: 0;
    top: 10px;
}
.nav-logo.collapsed {
    max-width: 40px;
    max-height: 40px;
}
.nav-logo.collapsed.hide {
    top: -10px;
}
.ant-layout-header {
    background: #fff;
    padding: 0 20px;
    text-align: left;
    border-bottom: 2px solid #d9d9d9;
}
header .ant-menu {
    line-height: 62px;
    position: fixed;
    top: 0;
    right: 0;
    background: transparent;
    border: 0;
}
header .ant-menu-item {
    padding: 0;
    min-width: 80px;
    text-align: center;
}
header .ant-menu-item .anticon {
    margin-right: 0;
    min-width: 16px;
    font-size: 16px;
}
header .ant-menu-horizontal > .ant-menu-item:hover,
header .ant-menu-horizontal > .ant-menu-submenu:hover,
header .ant-menu-horizontal > .ant-menu-item-active,
header .ant-menu-horizontal > .ant-menu-submenu-active,
header .ant-menu-horizontal > .ant-menu-item-open,
header .ant-menu-horizontal > .ant-menu-submenu-open,
header .ant-menu-horizontal > .ant-menu-item-selected,
header .ant-menu-horizontal > .ant-menu-submenu-selected {
    color: inherit;
    border-bottom: 2px solid transparent;
}
#reminderDrawer {
    margin-top: 64px;
}
@media (max-width: 767px) {
    header .ant-select {
        right: 150px !important;
    }
    header .ant-menu-item {
        min-width: 50px;
    }
}
@media (max-width: 620px) {
    .ant-layout-header:not(.shortHeader) {
        height: 108px;
    }
    .ant-layout.ant-layout-has-sider:not(.shortHeader) {
        top: 108px !important;
    }
    #reminderDrawer:not(.shortHeader) {
        margin-top: 108px;
    }
    header .ant-select {
        float: right;
        top: 64px !important;
        display: inline;
        left: 10px !important;
        right: 10px !important;
        width: auto !important;
    }
}
@media (max-width: 400px) {
    .fc-list-item td {
        padding: 8px 3px !important;
    }
    .fc-list-item td:first-child {
        padding-left: 10px !important;
    }
}
@media (max-height: 360px) {
    .bottomLeft {
        display: none;
    }
}
.ant-drawer-content,
.ant-drawer-header {
    background-color: #fafafa;
}
.ant-drawer-header {
    border-bottom: 0;
    padding: 10px 24px;
}
.reminder-drawer-title {
    text-align: center;
    text-transform: uppercase;
}
.reminder-drawer-title svg {
    color: var(--reminder) !important;
}
.ant-drawer-body {
    max-height: calc(100vh - 106px);
    overflow: auto;
    padding: 5px 10px 0px 10px;
}
#reminderDrawerNoReminders {
    text-align: center;
    margin-top: 10vh;
}
#reminderDrawerReminders .reminder_box {
    display: block;
    color: var(--black65) !important;
    height: 95px;
    padding: 5px 25px 5px 10px;
    background: #fff;
    border-width: 1px;
    border-style: solid;
    border-radius: 10px;
    border-color: var(--reminder) !important;
    border-left-width: 10px;
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;
    opacity: 1;
}
span.reminder-drawer-reminder-mark-done {
    color: var(--black65);
    position: absolute;
    top: -2px;
    right: 2px;
    font-size: 20px;
}
span.reminder-drawer-reminder-due {
    background: #eee;
    padding: 5px;
    border-radius: 15px;
    position: absolute;
    display: block;
    bottom: 5px;
    right: 5px;
    font-size: 11px;
    width: 75px;
    text-align: center;
}
#reminderDrawerReminders span.done span {
    text-decoration: line-through;
}
#calendarDatePickerWrapper {
    position: absolute; 
    right: 285px; 
    top: 60px; 
}
.date-picker-popup {
    left: inherit !important;
    top: inherit !important;
}
/* End Top Navigation */

/* Left Navigation */
.ant-layout-sider .ant-menu {
    text-align: left;
}
.ant-layout-sider, .ant-layout-sider .ant-menu {
    background-color: #fafafa;
}
aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger {
    max-width: 60px !important;
    min-width: 60px !important;
    width: 60px !important;
    flex: 60px !important;
}
aside ul.ant-menu-inline-collapsed{
    width: 60px;
}
aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-has-trigger {
    flex: 0 0 170px !important;
    max-width: 170px !important;
    min-width: 170px !important;
    width: 170px !important;
}
.ant-layout-sider .ant-menu-inline .ant-menu-item,
.ant-layout-sider .ant-menu-vertical .ant-menu-item {
    border-bottom: 1px solid #d9d9d9;
    margin: 0px;
    height: 50px;
    line-height: 50px;
}
.ant-layout-sider-trigger {
    position: fixed;
    top: 0;
    height: 62px;
    left: 180px;
    line-height: 62px;
    background: #fff;
    color: var(--black65);
    font-size: 1.3em;
    width: 20px !important;
    z-index: 3;
}
.ant-layout-sider-collapsed .ant-layout-sider-trigger {
    left: 55px;
}
@media (max-width: 639px) {
    .ant-layout-sider-trigger {
        display: none;
    }
}
.booking-type-header {
    font-weight: bold;
    border-bottom-color: transparent !important;
}
.event-menu-item {
    border-bottom-color: transparent !important;
    max-width: 170px !important;
}
.booking-type-header {
    text-align: center;
}
.sidebar-collapsed .event-menu-item {
    width: 60px;
}
.ant-layout-sider .ant-menu-inline-collapsed > .ant-menu-item .fa-fw + span,
.ant-layout-sider .ant-menu-inline-collapsed > div .ant-menu-item .fa-fw + span,
.ant-layout-sider .ant-menu-inline-collapsed .booking-type-header {
    display: inline-block;
    max-width: 0;
    opacity: 0;
}
.ant-layout-sider .ant-menu-item .fa-fw {
    margin-right: 10px;
}
.ant-tooltip-inner svg {
    display: none;
}
.sidebar-expanded .ant-badge-count {
    transform: translate(17px, -8px) scale(0.8)
}
.ant-tooltip .ant-badge-count,
.sidebar-collapsed .ant-badge {
    opacity: 1 !important;
    margin-left: -5px;
    top: -5px;
    transform: scale(0.75);
}
.ant-tooltip .ant-badge-count {
    display:none;
}
.sidebar-collapsed .ant-badge > span {
    opacity: 0 !important;
}
.ant-layout-sider .ant-menu-item-active {
    background: #fff;
    font-weight: bold;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item {
    border-left: 5px solid transparent;
    padding-left: 15px !important;
}    
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #fff;
    border-left-color: var(--black65);
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
    border-right: 0;
}
.ant-layout-sider .ant-menu-item-selected > a,
.ant-layout-sider .ant-menu-item-selected > a:hover {
    font-weight: bold;
    color: var(--black65);
}
.ant-layout-sider li.ant-menu-item:active {
    background: inherit;
    border-left-color: var(--black65);
}
.ant-layout-sider li.ant-menu-item:hover a,
.ant-layout-sider .ant-menu-item > a:hover {
    color: var(--black65);
}
.ant-menu-vertical .ant-menu-item,
.ant-badge {
    font-size: 13px;
}
.bottomLeft {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 170px;
}
.bottomLeft .ant-menu-item {
    border-bottom-width: 0 !important;
}
/* End Left Navigation */

/* Main Body */
.ant-layout {
    background: #fff;
}
h3 {
    margin: 15px auto;
}
main .svg-inline--fa,
.ant-modal .svg-inline--fa {
    margin-right: 5px;
}

/* Choose Driver / Student Screen */
.drivers-table,
.students-table {
    max-height: calc(100vh - 64px - 155px);
    overflow: auto;
}
.lessonplans-table {
    max-height: calc(100vh - 180px);
    overflow: auto;
}
@media (max-width: 620px) {
    .drivers-table,
    .students-table {
        max-height: calc(100vh - 108px - 155px);
    }
    .lessonplans-table {
        max-height: calc(100vh - 225px);
    }
}
/* End Choose Driver / Student Screen */

/** Student profile Screen */
.alignItemsLeft, .alignItemsLeft .ant-form-item-label{
    text-align: left;
}
span.profileSectionHeader {
    font-size: 20px;
    font-weight: bold;
    display: block;
    margin: 0 0 10px;
    text-transform: uppercase;
}
span.licenceDetailField {
    display: block;
    margin: 2px;
    font-size: 18px;
}
.profileSelect {
    width: 100%;
    margin: 10px 0px 0px;
    padding-right: 5%;
}

.reducedFormSpacing .ant-form-item{
    margin-bottom: 8px;
}

.parentContactInfo .ant-collapse-header {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.btn-profile, .ant-btn.btn-profile {
    padding: 0;
    width: calc(100% - 10px);
    margin: 4px 0;
    text-align: left;
    font-size: 18px;
    height: 35px;
}

.btn-profile button {
    width: 100%;
    text-align: left;
    height: 35px;
}

.btn-profile button {
    color: var(--black65) !important;
}

.btn-profile.booking button, .ant-btn.btn-profile.booking{
    background-color: var(--first_lesson-light);
}

.btn-profile.plans button, .ant-btn.btn-profile.plans{
    background-color:var(--test-light);
}
.ant-btn.btn-profile.event {
    border: 0;
}
.btn-profile.payment button, .ant-btn.btn-profile.payment{
    background-color: var(--booking-light);
}
.btn-profile .ant-btn-primary:hover,
.btn-profile .ant-btn-primary:focus {
    color: var(--black65) !important;
    border-color: var(--black65);
}

a.back-to-lesson {
    float: left;
    width: fit-content;
}
a.back-to-lesson button {
    height: 28px;
}
.ant-row.ant-form-item.inlineSwitchInput {
    display: block;
}

.ant-row.ant-form-item.inlineSwitchInput .ant-form-item-label{
    display: inline-block;
    width: calc(50% - 10px);
    text-align: left;
    overflow: inherit;
}

.ant-row.ant-form-item.inlineSwitchInput .ant-form-item-control-wrapper{
    display: inline-block;
    width: calc(50% - 10px);
}

.inlineSwitchInput button.ant-switch{
    width: 80% !important;
    text-align: left;
    padding-left: 10px;
    margin-left: 35px;
}
.pull-left {
    float: left;
}
.disabledSwitch {
    pointer-events: none;
}

.borderlessInputsContainer input{
    border:none;
}

.borderlessInputsContainer input:focus{
    border:inherit;
}

.borderlessInputsContainer textarea{
    border:none;
}

.borderlessInputsContainer textarea:focus{
    border:inherit;
}

.borderlessInputsContainer select{
    border:none;
}

.borderlessInputsContainer .ant-select-selection--single {
    border: 0;
}

.borderlessInputsContainer .ant-select-arrow {
    display: none;
}

.borderlessInputsContainer select:focus{
    border:inherit;
}

.borderlessInputsContainer .ant-input-number {
    border: inherit;
    width: -webkit-fill-available;
}

.ant-row.ant-form-item.headerInput {
    width: fit-content;
    display: inline-block;
}

.ant-row.ant-form-item.headerInput input {
    font-size: 22px;
    padding: 3px;
    line-height: 32px;
}

.ant-row.ant-form-item.headerInput.align-right input {
    text-align: right;
}

.inlineCalendar .ant-form-item-label, .inlineInput .ant-form-item-label {
    padding-bottom: 8px;
    line-height: inherit;
    display: inline-block;
    width: calc(50% - 5px);
}

.inlineCalendar.shortLabel .ant-form-item-label, .inlineInput.shortLabel .ant-form-item-label {
    width: calc(35% - 5px);
}

.inlineCalendar .ant-calendar-picker-input, .inlineInput .ant-input {
    font-size: 15px;
    padding-bottom: 0;
}

.inlineCalendar .ant-form-item-control-wrapper, .inlineInput .ant-form-item-control-wrapper {
    width: calc(50% - 5px);
    display: inline-block;
}

.inlineCalendar.shortLabel .ant-form-item-control-wrapper, .inlineInput.shortLabel .ant-form-item-control-wrapper {
    width: calc(65% - 5px);
}

.inlineCalendar .anticon{
    line-height: 1.85
}

.studentAddress {
    margin-top: 10px;
}

.studentAddress svg, .studentAddress .ant-form-item {
    display: inline-block;
}

.studentAddress .number {
    width: calc(20% - 5px);
}

.studentAddress .suburb, .studentAddress .street {
    width: calc(35% - 5px);
}

.studentAddress .ant-form-item .ant-select-selection__rendered,
.studentAddress .ant-form-item .ant-select {
    width: 100%;
}

.iconAsLabelFormItem .ant-form-item, .iconAsLabelFormItem svg {
    display: inline-block;
}

.iconAsLabelFormItem .ant-form-item {
    width: calc(85% - 10px);
}

.iconAsLabelFormItem svg {
    font-size: 20px;
    color:#a6a6a6;
    width: calc(15% - 10px) !important;
    margin-top: 10px;
}

.hiddenButtonWrapper {
    border: none;
    display: contents;
}

.ant-calendar-panel .ant-calendar-input {
    pointer-events: none;
}

/** End Student profile Screen */

/* Home Screen - Agenda View */
.homeAgenda {
    height: 100%;
    width: calc(100%);
}
@media (min-width: 1024px) {
    .homeAgenda {
        width: calc(100% - 256px);
    }
}
@media (max-width: 620px) {
    .homeAgenda td.fc-list-item-time {
        max-width: 40px;
        white-space: pre-wrap;
        text-align: center;
        font-size: 11px;
        padding-right: 10px !important;
    }
    .homeAgenda td.fc-widget-header {
        padding: 0 10px;
    }
    .homeAgenda span.fc-list-heading-main {
        font-size: 15px !important;
        line-height: 20px;
    }
}
.fc-listHomeAgenda-view {
    border: 0 !important;
}
.fc-listHomeAgenda-view .fc-scroller {
    height: calc(100vh - 64px) !important;
}
.fc-listHomeAgenda-view .fc-list-heading {
    text-transform: uppercase;
}
.fc-listHomeAgenda-view .fc-list-heading td {
    background-color: #fff !important;
}
.fc-listHomeAgenda-view .fc-list-heading,
.fc-listHomeAgenda-view .fc-list-item {
    padding: 0px 14px;
}
.fc-listHomeAgenda-view .fc-list-heading {
    line-height: 33px;
}
/* Hide the dots */
td.fc-list-item-marker {
    display: none;
}
.fc-list-item-title {
    font-size: 12px;
}
.fc-list-item-title a,
.fc-list-item-time {
    font-weight: bold;
    font-size: 13px;
}
/* Add the rounded borders and icon column */
.fc-list-table {
    width: 98% !important;
    margin: auto;
    border-collapse: separate !important;
    border-spacing: 0 10px !important;
}
tr.fc-list-item td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-left-width: 15px;
    border-left-style: solid;
    width: 24px;
    padding: 8px 0 8px 8px;
}
tr.fc-list-item td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-right-width: 2px;
}
tr.fc-list-item td {
    border-top-width: 2px;
    border-bottom-width: 2px;
}
/* Date Heading */
tr.fc-list-heading span,
tr.fc-list-heading a {
    font-size: 20px;
    color: rgba(0,0,0,0.5);
    text-decoration: none !important;
}
tr.fc-list-heading td {
    background: #fff !important;
}
.fc-listAgenda-view {
    border-color: #fff !important;
}
tr.fc-list-heading .fc-widget-header {
    border: 0;
}
/* Colour the Calendar Event Items */
a.fc-event,
a.fc-event:hover {
    color: #000;
}
/* Booking */
tr.fc-list-item.booking td,
.event-menu-item.booking,
a.fc-event.booking {
    border-color: var(--booking) !important;
    background-color: var(--booking-light) !important;
}
/* First Lesson */
tr.fc-list-item.first_lesson td,
.event-menu-item.first_lesson,
a.fc-event.first_lesson {
    border-color: var(--first_lesson) !important;
    background-color: var(--first_lesson-light) !important;
}
/* Event */
tr.fc-list-item.event td,
.event-menu-item.event,
a.fc-event.event {
    border-color: var(--event) !important;
    background-color: var(--event-light) !important;
}
/* Test */
tr.fc-list-item.test td,
.event-menu-item.test,
a.fc-event.test {
    border-color: var(--test) !important;
    background-color: var(--test-light) !important;
}
/* Reminder */
tr.fc-list-item.reminder td,
a.fc-event.reminder {
    border-color: var(--reminder) !important;
    background-color: var(--reminder-light) !important;
}
/* Tentative (Checked Overlay - @todo: Tentative Bookings may be getting removed) */
tr.fc-list-item.tentative td,
a.fc-event.tentative {
    background-image: linear-gradient(45deg, #BBBBBB99 25%, transparent 25%),
                    linear-gradient(-45deg, #BBBBBB99 25%, transparent 25%), 
                    linear-gradient(45deg, transparent 75%, #BBBBBB99 75%), 
                    linear-gradient(-45deg, transparent 75%, #BBBBBB99 75%);
    background-size: 10px 10px;
    background-position: 0 0, 0 5px, 5px -5px, -5px 0px;
}
/* Reservations (Striped Overlay) */
tr.fc-list-item.reservation td,
a.fc-event.reservation {
    background: repeating-linear-gradient(
        135deg,
        transparent,
        transparent 5px,
        #BBB 5px,
        #BBB 10px
        );
}
/* Shifts/Availability (Reverse - We highlight what is NOT a shift) */
.not-working {
    background-color: #DCDCDC !important;
}
/* End Home Screen - Agenda View */


.hidden {
    display: none !important;
}

.ant-row-flex {
    margin-bottom: 15px;
}

h1, h2, h3, h4, h5, h6 {
    color: var(--black65);
}

.ant-alert.ant-alert-banner {
    max-width: 500px;
    text-align: left;
    left: 0;
    right: 0;
    margin: auto;
    margin-bottom: 25px;
}

/** Forms **/
.form-buttons {
    display: flex;
    justify-content: space-evenly;
    max-width: 600px;
    margin: auto;
}
#reminderForm,
#studentAddForm {
    width: 90%;
    margin: auto;
    text-align: left;
}
.ant-switch-inner {
    margin: auto 24px !important;
    width: 75px;
}
@media (max-width: 640px) {
    .ant-form-item {
        margin-bottom: 5px;
    }
}

.pageDescriptionContainer {
    width: 50%;
    margin: 10px auto 30px;
}

.pageDescriptionContainer span.ant-typography.ant-typography-secondary {
    display: inherit;
}

.project-logo {
    width:50px;
}

/* Dashboard Display Box Stylings */
.ant-card {
    height: 100%;
}
.ant-card-body {
    overflow: auto;
}

/* Spinner/loading page stylings */
.spinner {
    z-index: 2;
    width: 100%;
    height: -webkit-fill-available;
    top: 0;
    left: 0;
    position: fixed;
}
.full-page-spinner {
    background: transparent;
}
.spinner .ant-spin {
    position: absolute;
    left:0;
    right:0;
    margin: auto;
    top: 30vh;
}
.spinner .ant-spin .ant-spin-dot {
    width: 10vh;
}
.mini-spinner .ant-spin .ant-spin-dot {
    width: 6vh;
}
.inline-spinner {
    position: relative;
    margin: auto;
    width: fit-content;
}
.inline-spinner .ant-spin {
    position: relative;
    top: auto;
}
.inline-spinner .ant-spin .ant-spin-dot {
    width: 6vh;
}
.full-page-spinner .ant-spin .ant-spin-dot {
    width: 40vh;
}
.mega-spinner .ant-spin .ant-spin-dot {
    width: 25vh;
}
.calendar-spinner {
    height: initial;
}
.spinner.inline-spinner {
    width: 50px;
    position: relative;
    height: 50px;
    margin: 10px auto;
}
.inline-spinner .ant-spin.ant-spin-spinning {
    top: 0;
}
.inline-spinner i {
    width: 50px !important;
}

.ant-avatar {
    width: 100px;
    height: auto;
    max-height: 100px;
}

/* Desktop Only Stylings */
@media only screen and (min-width: 576px) {
    .ant-form-item-control {
        max-width: 500px;
    }
}

#mainBody {
    padding: 15px;
    max-height: calc(100vh - 64px);
    margin-bottom: 0;
    overflow: auto;
}
@media (max-width: 620px) {
    #mainBody {
        max-height: calc(100vh - 108px);
    }
}
#newButton {
    position: fixed;
    bottom: 10px;
    right: 10px;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
        0px 6px 10px 0px rgba(0, 0, 0, 0.04),
        0px 1px 18px 0px rgba(0, 0, 0, 0.02);
    background-color: var(--first_lesson);
    border-color: var(--first_lesson-light);
    width: fit-content;
    height: 45px;
    border-radius: 25px;
    line-height: 0;
    font-size: 20px;
    padding: 0 20px;
}

/*
 * Reminders
 */
.space-evenly {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 20px;
    margin-top: 10px;
}
@media (max-width: 767px) {
    .space-evenly {
        display: block;
    }
    .space-evenly > a,
    .space-evenly > button {
        display: inline-block;
        margin-bottom: 10px;
        margin-right: 10px;
    }
}
.reminders-table-row svg {
    color: var(--event);
    font-size: 18px;
    margin-right: 0;
}
.reminders-table-row td {
    position: relative;
}
.reminders-table-row span.done:before {
    content: " ";
    position: absolute;
    top: 50%;
    left: 0;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: var(--black65);
    width: 100%;
}
.reminderList {
    white-space: nowrap;
}
span.datebubble {
    background: #eee;
    padding: 5px 10px;
    border-radius: 15px;
}

.fe-pulse .ant-badge-count {
    animation: pulse 4s;
    -webkit-animation: pulse 4s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}
@keyframes pulse {
    from { transform: scale(0.65) translate(50%, -50%); transform-origin: 100% 0%;}
    50% { transform: scale(1.22) translate(50%, -50%); transform-origin: 100% 0%;}
    to { transform: scale(0.65) translate(50%, -50%); transform-origin: 100% 0%;}
}
@-webkit-keyframes pulse {
    from { transform: scale(0.65) translate(50%, -50%); transform-origin: 100% 0%;}
    50% { transform: scale(1.22) translate(50%, -50%); transform-origin: 100% 0%;}
    to { transform: scale(0.65) translate(50%, -50%); transform-origin: 100% 0%;}
}

.reminder-pulse {
    color: var(--reminder);
    animation: pulse 4s;
    -webkit-animation: pulse 4s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}
@keyframes pulse {
    from { transform: scale(0.65) }
    50% { transform: scale(1.22) }
    to { transform: scale(0.65) }
}
@-webkit-keyframes pulse {
    from { transform: scale(0.65) }
    50% { transform: scale(1.22) }
    to { transform: scale(0.65) }
}

/* Reports */
table.inactiveStudentTable {
    min-width: 370px;
}

table.inactiveStudentTable table, table.inactiveStudentTable th, table.inactiveStudentTable td {
    border:  1px solid black;
}

.inactiveStudentTable th {
    width: 25%;
    min-width: 90px;
}

.reportForm .ant-form-item {
    margin-bottom: 5px;
}

.reportForm .ant-input[disabled] {
    color: black;
    background-color: white;
    border: none;
}

.twoFieldsPerRow .ant-row {
    width: 50%;
    display: inline-block;
}

.fourFieldsPerRow .ant-row {
    width: 25%;
    display: inline-block;
}

/* Diary */
.fc-scroller {
    height: calc(100vh - 242px) !important;
}
.fc-listAgenda-view .fc-scroller {
    height: calc(100vh - 160px) !important;
}

.full-height-card .ant-card-body {
    height: 100%;
}
.fc h1, .fc h2, .fc h3, .fc h4, .fc h5, .fc h6 {
    color: inherit;
}
.fc-view table table th:first-child, .fc-view table table td:first-child {
    width: 50px !important;
}
.fc-body a[data-goto], .fc-head a[data-goto] {
    display: block;
    color: var(--black65);
}
.fc-dailytotal {
    font-size: 10px;
}
.fc-dailytotal:after {
    content: " Hours";
}
.fc-weeklytotal {
    font-size: 10px;
    text-align: center;
}
.fc-time-grid-container .fc-content {
    white-space: normal !important;
}
.fc-event {
    border-width: 0 !important;
    border-left-width: 5px !important;
    font-size: 0.75em !important;
    padding-left: 2px;
}
.nobreak {
    white-space: nowrap;
}
#calendarWrapper .spinner {
    display: block;
    position: absolute;
    z-index: 10;
}

.fc-header-toolbar button {
    min-height: 42px;
    min-width: 42px;
}
.fc-icon-download {
    content: url(/img/download.svg);
    vertical-align: -0.125em;
}
span.payment_icon {
    float: right;
    border-width: 1px;
    border-style: solid;
    border-color: var(--black65);
    color: var(--black65);
    border-radius: 50%;
    text-align: center;
    font-weight: bold;
    background: #fff;
    padding: 0px;
    width: 25px;
    height: 25px;
    font-size: 10px;
    line-height: 25px;
}
span.payment_icon.week {
    width: 18px;
    height: 18px;
    font-size: 8px;
    line-height: 18px;
    margin-top: -7px;
    margin-right: 3px;
    font-weight: normal;
}
span.payment_icon.day {
    margin-right: 5px;
    margin-top: -5px;
}
span.payment_icon.lesson {
    float: none;
    border-width: 2px;
    width: 28px;
    height: 28px;
    line-height: 24px;
    position: absolute;
    margin-top: 5px;
    color: var(--event);
    border-color: var(--event);
}

/** Messages **/
.small-message-content {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 37vw;
    overflow: hidden;
}
@media (min-width: 769px) {
    .small-message-content {
        max-width: 48vw;
    }
}
.unread {
    font-weight: bold;
}
.urgent-message {
    animation: pulsate 4s ease-in-out;
    animation-iteration-count: infinite; 
    -webkit-animation: pulsate 4s ease-in-out;
    -webkit-animation-iteration-count: infinite; 
}
@keyframes pulsate {
    0% { background-color: inherit; }
    50% { background-color: #1890ff59; }
    100% { background-color: inherit; }
}
@-webkit-keyframes pulsate {
    0% { background-color: inherit; }
    50% { background-color: #1890ff59; }
    100% { background-color: inherit; }
}

/** Availability Styles */
.availabilityCalendar .fc-scroller {
    height: calc(100vh - 175px) !important;
}
.availabilityCalendar .fc-today {
    background: inherit !important;
}
.peak-times {
    background-color: var(--dark-green-light) !important;
}
.low-availability {
    color: var(--test);
}
.availabilityCalendar .fc-content {
    width: calc(100% - 7px);
    height: 100%;
    display: table;
}
.availabilityCalendar .fc-title {
    font-size: 1.5em;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
}
.availabilityCalendar .fc-time-grid-event.fc-short .fc-title {
    margin-left: 2px;
}
.availabilityCalendar .fc-time-grid-event.fc-short .fc-time:after,
.availabilityCalendar .fc-time-grid-event.fc-short .fc-time:before {
    content: '';
}
.availabilityCalendar .fc-time-grid-event.fc-short .fc-time span {
    display: inline;
}
.availabilityCalendar .fc-time-grid-event .fc-time {
    display: table-caption;
    margin-bottom: -12px;
}
.availability-delete {
    color: var(--test);
    position: absolute;
    right: 4px;
    top: 4px;
    z-index: 1000;
}
/** End Availability Styles */

/** Lesson View **/
#lessonForm {
    margin-bottom: 64px;
}
#lessonForm .ant-form-item-control {
    text-align: left;
}
#lessonForm .ant-form-item-control input,
#lessonForm .ant-select,
#lessonForm .ant-cascader-picker,
#lessonForm textarea {
    margin-right: 10px;
    max-width: 90%;
}
#lessonForm .payment-buttons {
    max-width: 90%;
    display: flex;
    justify-content: space-evenly;
}
#lessonForm input.time-input {
    max-width: 100px;
}
.paid-lesson {
    position: absolute;
    right: 20px;
    color: var(--first_lesson);
}
.adjust-time-modal {
    text-align: center;
}
.adjust-time-modal .ant-modal-header {
    text-align: left;
}
.adjust-time-modal .ant-modal-body{
    padding: 10px 24px;
}
.adjust-time-modal .ant-calendar-picker {
    margin-bottom: 10px;
}
.adjust-time-modal .fc-scroller {
    height: calc(30vh) !important;
}
.adjust-time-modal .ant-btn-group {
    margin-left: 5px;
}
.adjust-time-modal .ant-btn-dashed {
    padding-left: 8px;
    font-size: 10px;
    padding-right: 8px;
    height: 32px;
    line-height: 32px;
}
.adjust-time-modal .fc-axis {
    width: 43px;
}
.ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date::before {
    border: 0;
}
.overlap-alert {
    width: 60%;
    margin: 0 10px !important;
}
.cancel-lesson-modal .ant-modal-confirm-btns {
    margin-top: -35px;
}
.update-lesson-modal .ant-modal-confirm-btns {
    margin-top: 0px;
}

/* Recurring Dates Section: */
div.inline-select {
    display: inline;
}
div.inline-select div {
    display: inline-block;
    min-width: 20px;
}
#recurringDates {
    border: 1px solid #d9d9d9;
    color: var(--black65);
    max-width: 450px;
    min-width: 300px;
    min-height: 125px;
    border-radius: 4px;
    padding: 5px;
}
#recurringDates .fa-times-circle {
    color: var(--test);
}
#recurringDates .fa-check-circle {
    color: var(--first_lesson);
}
/** End Lesson View **/

/** Event View **/
#attendeesTable table,
#sitinTable table {
    border: 1px solid rgb(217, 217, 217);
    border-radius: 5px;
    width: 90%;
}
#sitinTable table {
    border-top: 0;
}
#attendeesTable td {
    padding: 5px;
    border-bottom: 0;
}
#attendeesTable td:first-child {
    /*width: 60%;*/
}
#attendeesTable td:nth-child(2) {
    text-align: right;
    padding-right: 5px;
}
#attendeesTable a.ant-btn-dashed {
    width: 90% !important;
}
#sitinTable td {
    height: 43px;
    padding: 5px;
    border-bottom: 0;
    border-top: 0;
}
#module2Details tr:hover > td {
    background-color: inherit;
}
#module2Details tr.empty {
    background-color: #DCDCDC;
}
#module2Details tr.vacancies {
    background-color: var(--reminder-light);
}
#module2Details tr.full {
    /*background-color: var(--first_lesson-light);*/
}
#module2Details th {
    text-align: center;
    white-space: nowrap;
}
#module2Details td {
    line-height: 40px;
    white-space: nowrap;
}
.event-page .ant-btn-dashed svg {
    margin-right: 0;
}
td.event-actions {
    width: 200px;
    text-align: center;
}
td.event-actions button:not(:last-child) {
    margin-right: 5px;
}
.no-decoration,
.no-decoration:active,
.no-decoration:focus,
.no-decoration:hover {
    text-decoration: none !important;
    color: var(--black65) !important;
    border: 0 !important;
    padding: 0 !important;
    text-align: left;
}
td.right-align {
    text-align: right;
    padding-right: 10px !important;
}
#mod2EnrolTable td,
#mod2RemoveTable td,
#mod2SwapTable td,
#mod2SwapTableSwap td,
#mod2RemoveSitinsTable td {
    padding: 5px 0;
}
#mod2EnrolTable .ant-form-item,
#mod2RemoveTable .ant-form-item,
#mod2SwapTable .ant-form-item,
#mod2SwapTableSwap .ant-form-item,
#mod2RemoveSitinsTable .ant-form-item {
    margin: 0;
}
td.modal-checkbox {
    text-align: center;
    width: 50px;
}
#mod2SwapTable {
    margin-bottom: 30px;
}
#mod2SwapTable td,
#mod2SwapTableSwap td {
    width: 45%;
}
#mod2SwapTable td:first-child,
#mod2SwapTableSwap td:first-child {
    width: 10%;
}
span.swapTitle {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
}
#descriptor-spans span {
    width: 60px;
    display: inline-block;
}

/* Attendance */
h1.attendance-header {
    font-size: 30px;
}
h2.attendance-header {
    font-size: 26px;
}
.access-code {
    font-family: Arial;
}
#markAttendance {
    margin-top: -15px;
    margin-bottom: 20px;
}
#studentSignInStep2Form .ant-form-item {
    margin-bottom: 15px;
}
#studentSignInStep2Form p,
#studentSignInStep2Form li {
    line-height: 20px;
}
@media (max-width: 767px) {
    #studentSignInStep2Form #login_dob,
    #studentSignInStep2Form #login_licence_expiry {
        display: inherit;
    }
    #studentSignInStep2Form .ant-input-number {
        width: 100%;
    }
}
.no-keyboard .ant-calendar-input-wrap {
    display: none;
}
.sigCanvas {
    border: 1px solid #d9d9d9;
    border-radius: 20px;
    width: 440px;
    height: 220px;
}
.sigCanvasBig {
    border: 1px solid #d9d9d9;
    border-radius: 20px;
    width: 840px;
    height: 420px;
}
.step2-modal-big-sig .ant-modal-body {
    text-align: center;
}
@media (max-width: 1023px) {
    .sigCanvas {
        width: 410px;
        height: 205px;
    }
    .sigCanvasBig {
        width: 620px;
        height: 310px;
    }
}
@media (max-width: 767px) {
    .sigCanvas {
        width: 410px;
        height: 205px;
    }
    .sigCanvasBig {
        width: 570px;
        height: 285px;
    }
}
@media (max-width: 639px) {
    .sigCanvas {
        width: 270px;
        height: 135px;
    }
    .sigCanvasBig {
        width: 290px;
        height: 145px;
    }
}

/* Increase size of input text to prevent Zoom-in of fields */
@media (max-width: 767px) {
    #loginForm input,
    #feedbackForm .ant-input,
    #studentSignInStep2Form .ant-input,
    #feedbackForm .ant-select-selection-selected-value,
    #studentSignInStep2Form .ant-select-selection-selected-value,
    #feedbackForm .ant-select-search__field,
    #studentSignInStep2Form .ant-select-search__field,
    #feedbackForm .ant-select-search--inline,
    #studentSignInStep2Form .ant-select-search--inline,
    #feedbackForm .ant-input-number-input,
    #studentSignInStep2Form .ant-input-number-input
    {
        font-size: 16px;
    }
}

.question-table {
    display: table;
    border-collapse: collapse;
}
.header-row,
.question-row {
    display: table-row;
    width: 100%;
    line-height: 60px;
}
.header-row {
    font-weight: bold;
    font-size: 16px;
}
.question-row {
    border-top: 1px solid var(--black65);
    height: 60px;
}
.question-row.comment {
    border-top: 0;
}
.question-column {
    display: table-cell;
    width: 50%;
    vertical-align: top;
    line-height: normal;
    padding: 20px 10px 15px 0;
}
.answer-column {
    display: table-cell;
    width: 40px;
    text-align: center;
}
.answer-column .ant-radio-wrapper {
    margin: auto;
}
.answer-column-comment {
    text-align: left;
}
.question-row .answer-column-comment textarea {
    max-width: 495px;
    margin: 15px 0;
}
#feedbackForm .ant-row.ant-form-item {
    margin-bottom: 0;
    padding-top: 10px;
}
#feedbackForm .mobile-only {
    display: none;
}
@media (max-width: 1023px) {
    #feedbackForm .question-column {
        display: block;
        width: 100%;
        padding: 20px 0 0 0;
    }
    #feedbackForm .ant-row.ant-form-item {
        margin-top: 0;
        padding-top: 0;
        margin-bottom: 15px;
    }
    #feedbackForm .mobile-only {
        display: block;
    }
    #feedbackForm .header-row .answer-column-wrapper {
        display: none;
    }
    #feedbackForm .ant-form-item-control {
        max-width: none; 
        text-align: center;
    }
    #feedbackForm .question-row.comment .question-column {
        display: none;
    }
    #feedbackForm .question-row.comment textarea {
        margin: 0;
    }
}
/** End Event View **/

/** Payment View and Styles **/
#ipsi-trent {
    text-align: center;
}
#ipsi-form {
    width: 100%;
    max-width: 600px;
    border: 0;
    height: 350px;
}
.pay-aged-assessment-modal .ant-modal-body {
    min-height: 425px;
}
.paymentOptionContainer {
    display: inline-block;
    margin-bottom: 20px;
    width: 100%;
}
.cards-table .ant-table-thead > tr > th,
.cards-table td {
    text-align: center;
}
.cards-table .actions {
    white-space: nowrap;
}
.cards-table button + button {
    margin-left: 10px;
}
.cards-table .edit-button .svg-inline--fa {
    margin-right: 0;
}

.lessonPackageDisplay {
    width: calc(25% - 10px);
    min-height: 140px;
    float: left;
    border: 1px solid #fac22e;
    background: #f8f8f8;
    margin: 25px 5px;
    padding: 5px;
    cursor: pointer;
}

@media (max-width: 800px) {
    .lessonPackageDisplay {
        width: calc(50% - 10px);
        margin: 10px 5px;
    }
}

.lessonPackageDisplay:hover, .lessonPackageDisplay.active {
    background: #fec52e;
    box-shadow: 6px 6px 5px #b1b1b1;
}

.ant-input-number.full-width {
    width: 100%;
}

.ant-input-number.half-width, .ant-input.half-width, .ant-calendar-picker.half-width {
    width: 50%;
    float: left;
}

.ant-form-item-children .ant-input-group .ant-row.ant-form-item {
    display: inline-block;
    float: left;
    margin: 0 5px 0;
}

.lessonNumberSelect {
    width:75px;
}

.ant-btn.goBack {
    display: block;
}
/** End Payment View and Styles **/

/** Lesson Plan View and Styles **/
.lessonPlanLessonDetails {
    font-size: 25px;
    margin-bottom: 20px;
}
.lessonPlanLessonDetails div {
    margin: 5px;
}
#lessonPlanForm .ant-select-selection--multiple .ant-select-selection__choice {
    float: none;
    text-align: left;
}
#lessonPlanForm .ant-select-selection--multiple .ant-select-selection__rendered {
    margin-left: 11px;
}
#lesson_plan_form_new_topics,
#lesson_plan_form_practice_topics {
    width: 100% !important;
}
/** End Lesson Plan View and Styles **/

/** Driver Profile Screen **/
#dtProfileForm .ant-calendar-picker-input.ant-input-disabled,
#dtProfileForm .ant-select-disabled .ant-select-selection {
    color: var(--black65);
    background-color: #fff;
}
.compliance-warning {
    margin-bottom: 24px;
}
.compliance-warning .ant-alert {
    margin: 0;
    max-width: 500px;
}
@media (max-width: 767px) {
    .compliance-warning {
        margin-bottom: 5px;
    }
}
.ant-upload {
    width: 100%;
}
.dt-compliance-history {
    margin: 0;
    width: 45%;
    text-align: center;
}
.dt-compliance-table .ant-table-body {
    height: 400px;
    overflow: auto;
    max-height: 60vh;
}
@media (max-width: 640px) {
    .dt-compliance-history {
        width: 100%;
    }
}
/** End Driver Profile Screen **/

/** PRINT SPECIFIC STYLES **/
@media print {
    .ant-layout-sider {
        display: none !important;
    }
}

body.sandbox:before {
    background: red;
    bottom: 45px;
    content: "TRAINING SITE";
    font-size: 17px;
    font-weight: bold;
    height: 30px;
    left: -90px;
    line-height: 30px;
    position: fixed;
    text-align: center;
    transform: rotate(45deg);
    width: 300px;
    z-index: 9000;
}
